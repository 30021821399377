import { createSlice } from '@reduxjs/toolkit';

const selectedchannelsSlice = createSlice({
  name: 'selectedchannels',
  initialState: {
    data: [],
  },
  reducers: {
  
    fetchselectedchannelsSuccess: (state, action) => {
    
      state.data = action.payload;

    },

  },
});

export const {
  fetchselectedchannelsSuccess,
} = selectedchannelsSlice.actions;

export default selectedchannelsSlice.reducer;