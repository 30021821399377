import { createSlice } from '@reduxjs/toolkit';

const runProcedureSlice = createSlice({
  name: 'runProcedure',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchRunProcedureStart: (state) => {
      state.loading = true;
    },
    fetchRunProcedureSuccess: (state, action) => {    
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchRunProcedureFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
    fetchRunProcedureStart,
    fetchRunProcedureSuccess,
    fetchRunProcedureFailure,
} = runProcedureSlice.actions;

export default runProcedureSlice.reducer;
