import * as React from "react";
import {Provider} from "react-redux";
import store from "./src/utils/store";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

export const wrapRootElement = ({element}) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{element}</Provider>;
    </QueryClientProvider>
  );
};
