import { createSlice } from '@reduxjs/toolkit';

const runsAssignedBySlice = createSlice({
  name: 'runsAssignedBy',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchrunsAssignedByStart: (state) => {
      state.loading = true;
    },
    fetchrunsAssignedBySuccess: (state, action) => {    
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchrunsAssignedByFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
    fetchrunsAssignedByStart,
    fetchrunsAssignedBySuccess,
    fetchrunsAssignedByFailure,
} = runsAssignedBySlice.actions;

export default runsAssignedBySlice.reducer;
